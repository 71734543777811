.button-bar {
  text-align: right;
  .dx-button {
    margin-left: 10px;
  }
}

app-button {
  &.small {
    .dx-button-content, .dx-button-text {
      text-transform: none;
    }
  }
}

.dx-button {
  font-size: $napp-button-font-size;

  .dx-button-content {
    text-transform: uppercase;
    padding: 6px 12px;
  }

  &.small {
    .dx-button-content {
      text-transform: none;
    }
  }

  &.danger {
    .dx-button-content {
      color: napp-theme-color(indication, danger)
    }
  }

  &.dx-button-normal.dx-button-mode-text {
    color: napp-theme-color(neutral, dark);
  }

  &.app-button {
    .dx-button-content {
      display: flex;
      justify-content: center;
    }
    .dx-loadindicator {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      &.button-indicator.indicator-only {
        position: absolute;
        top: 7px;
        margin-right: 0;
      }
    }
  }

  &.dx-button-default {
    .dx-loadindicator-segment2 .dx-loadindicator-segment-inner {
      border-color: lighten(napp-theme-color(primary, base), 30);
    }
  }

  .dx-button-text {
    font-weight: normal;
  }
}

.dx-button-has-icon {

  border-radius: 0;

  &.icon-small {
    i.dx-icon {
      font-size: 0.8rem;
    }
  }

  .dx-button-content {
    padding: 6px;
  }

  //&:hover,
  //&:active,
  //&:focus {
  //  background-color: transparent;
  //}
}

.dx-fa-button.dx-fa-button-main .dx-overlay-content {
  max-width: 56px;
  max-height: 56px;
  background-color: $napp-color-primary;
  color: legible-text-color($napp-color-primary);

  &.dx-state-hover {
    background-color: darken($napp-color-primary, 10%);
    color: legible-text-color(darken($napp-color-primary, 10%));
  }
}

dx-button.app-page-nav-button {
  &.left {
    .dx-button-content {
      padding-left: 0;
    }
  }

  &.right {
    .dx-button-content {
      padding-right: 0;
    }
  }

  i.dx-icon {
    font-size: 1.5em;
  }
}

.dx-dropdownbutton {
  margin-left: 5px;
  .dx-buttongroup-item {
    border: none;

    .dx-button-content {
      padding-left: 6px !important;
      padding-right: 6px !important;
      .dx-icon{
        margin-top: -4px;
      }
    }
  }
}

.dx-toolbar-button .dx-button-has-text:not(.dx-button-back) .dx-icon,
.dx-button-has-icon.dx-button-has-text .dx-icon {
  line-height: 22px;
}

.cursor-pointer {
  cursor: pointer;
}
