* { -webkit-box-sizing: border-box; box-sizing: border-box; }

.bottombar {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $bottombar-height;
  background: $napp-color-white;
  z-index: 1001;
  -webkit-box-shadow: 0px -3px 7px -4px $napp-color-ultra-light-gray;
  -moz-box-shadow: 0px -3px 7px -4px $napp-color-ultra-light-gray;
  box-shadow: 0px -3px 7px -4px $napp-color-ultra-light-gray;
}

#bottombar a {
  display: inline-block;
  width: 33%;
  text-align: center;
  color: napp-theme-color(neutral, dark);
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  position: relative;
  top: 2%;
  font-size: $napp-body-font-size;
  text-decoration: none;

  &.active {
    color: black;
  }
}

#bottombar i {
  padding: 10px 0 3px;
  margin: 0;
}

#bottombar p {
  padding: 0 0 5px;
  margin: 0;
}
