@mixin sidebar {

  #sidebar-left, #sidebar-right {
    width: $napp-sidebar-desktop-width;
  }

  #sidebar-left {
    &.closed {
      margin-left: $napp-sidebar-desktop-width * -1;
    }
  }

  #sidebar-right {
    &.closed {
      margin-right: $napp-sidebar-desktop-width * -1;
    }
  }

  .sidebar-overlay {
    &.active {
     display: none;
    }
  }
}
