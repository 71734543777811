.profile-img-container {
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  padding: 5px;
  margin: 30px 0;
  width: 120px;
  height: 120px;
  position: relative;

  .author-image-container {
    background-color: napp-theme-color(neutral, light);
  }

  app-profile-image {
    img {
      border-radius: 50%;
    }
  }

  .dx-dropdownbutton {
    background-color: white;
    border-radius: 50%;
    box-shadow: $napp-box-shadow;
    position: absolute;
    bottom: 0;
    right: 0;

    .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-hover,
    .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-focused {
      border-radius: 50% !important;
    }
  }
}
