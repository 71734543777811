%logo-style {
  content: "\00A0";
  height: 1em;
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: contain;
}

.napp-logo {
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;

  @each $logo-name in map-keys($napp-logos) {
    $logo-data: map-get($napp-logos, $logo-name);
    &.napp-logo-#{$logo-name}:before {
      display: inline-block;
      @extend %logo-style;
      width: (
        calc(map-get($logo-data, "width") * 1em / map-get($logo-data, "height"))
      );
      background-color: #{map-get($logo-data, 'color')};
      mask-image: url("/assets/themes/#{map-get($logo-data, 'url')}");
    }
  }
}
