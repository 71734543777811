@mixin filemanager {
  ul.file-browser {
    li {
      width: 25%;
    }
  }

  .file-upload {
    image-cropper {
      padding: 50px 0;
    }
  }
}
