dx-data-grid {
  .dx-header-row > td {
    font-size: $napp-header-font-size !important;
  }

  .dx-datagrid-borders > .dx-datagrid-headers, .dx-datagrid-borders > .dx-datagrid-rowsview,
  .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-left: 0;
    border-right: 0;
  }

  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused),
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link {
    background-color: napp-theme-color(neutral, light);
    color: $napp-body-text-color;
  }

  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
    border-bottom: none;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    line-height: 20px;
  }
}

