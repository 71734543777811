.dx-tag {
  .dx-tag-content {
    border-radius: $napp-border-radius;
  }
}

.feed-type-tag-list {
  margin-bottom: 20px;
}

.feed-tag-button {
  margin-bottom: 10px;
  display: inline-block;

  .dx-button.dx-button-default {
    border-radius: 5px;
    background-color: napp-theme-color('neutral', 'base');
    color: napp-theme-color('neutral', 'dark');
    box-shadow: none;
    margin-right: 10px;
    border: 1px solid napp-theme-color('neutral', 'dark');
  }

  &.subscribed .dx-button.dx-button-default {
    background-color: napp-theme-color('primary', 'light');
    color: legible-text-color(napp-theme-color('primary', 'light'));
    border: none;
  }
}
