.tox {

  .tox-dialog {
    top: var(--sat);
  }

  .tox-dialog__body {
    .tox-dialog__body-nav-item:focus {
      background-color: transparent;
    }

    .tox-dialog__body-nav-item--active {
      border-bottom: 2px solid #c00;
      color: #c00;
    }
  }

  .tox-dialog__footer {
    .tox-button {
      border-radius: 4px;
      background-color: #c00;
      color: #fff;
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
      border: none;

      &:hover {
        background-color: #a10000 !important;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .24) !important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .24) !important;
      }

      &.tox-button--secondary {
        color: napp-theme-color(neutral, dark);
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;

        &:hover {
          background-color: rgba(0, 0, 0, .08) !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
