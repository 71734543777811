.app-loading-bar {
  position: fixed;
  top: 0;
  top: var(--sat);
  width: 100%;
  z-index: 3000;

  .dx-progressbar-animating-container {
    background-image: linear-gradient(-90deg, transparent 5%, $napp-color-primary, transparent 95%);
    background-color: lighten($napp-color-primary, 90);
  }
}

.dx-loadindicator.item-loader {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  margin-left: -10px;
}
