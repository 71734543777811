.dx-form,
.dx-field-item {
  font-size: $napp-form-font-size;
}

.dx-form .item-group {
  padding: 20px !important;
  box-shadow: $napp-box-shadow;
  margin-bottom: 20px;
}

.feed-post-form {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 577px) {
    .dx-form .item-group {
      box-shadow: none;
      padding-block: 10px !important;
      padding-inline: 0 !important;
    }

    .dx-accordion-wrapper .dx-template-wrapper {
      padding-inline: 0 !important;
    }
  }
}

.dx-form-group-caption,
.dx-field-label.dx-field-label-small {
  font-size: $napp-body-font-size;
}

.dx-field-item-label-text,
.dx-field-label:not(.dx-field-label-small) {
  font-size: $napp-small-font-size;
}

.dx-form-group-with-caption > .dx-form-group-content {
  border: none;
  padding-top: 5px;
}

.dx-state-readonly.dx-state-focused::before {
  border: none;
}

.dx-field {
  display: block;
  margin-bottom: 30px;

  .dx-field-label:not(.dx-field-label-small) {
    margin-bottom: 10px;
  }

  .dx-field-label:not(.dx-field-label-small),
  .dx-field-value:not(.dx-field-value-small) {
    float: none;
    width: 100%;
  }

  .dx-field-label.dx-field-label-small {
    width: 60%;
  }

  .dx-field-value.dx-field-value-small {
    width: 40%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button):not(.dx-field-value-small),
  .dx-field-value-static {
    width: 100%;
  }
}

.dx-invalid.dx-texteditor .dx-texteditor-input-container:after {
  // line-height: 27px;
}

.dx-invalid-message.dx-overlay-wrapper {
  transform: translate(0px, 0px) !important;
}

/* Handle Read only mode */
.dx-show-invalid-badge.dx-state-readonly {
  .dx-texteditor-input-container::after {
    content: '' !important;
    display: none;
  }
  .dx-invalid-message {
    display: none;
  }
}

.dx-show-invalid-badge.dx-state-readonly.dx-texteditor.dx-invalid::after {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42) !important;
}

/* https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

.dx-invalid {
  input,
  textarea {
    background: rgba(255, 0, 0, 0.5) !important;
  }
}

.dx-htmleditor-content {
  font-family: 'Inter', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
}

.dx-mention {
  border-radius: 0.5em;
  background-color: $napp-color-lighten-20;
  color: legible-text-color($napp-color-lighten-20) !important;
  padding: 0.15em;
  font-weight: 400;
  white-space: nowrap;
}
