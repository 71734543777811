$fonts-loaded: false !default;
@if not $fonts-loaded {
  // make sure fonts get only included once
  $fonts-loaded: true;

  // Fonts

  @font-face {
    font-family: 'Corporate S Napp';
    src: url('../fonts/corporates/CorporateS-Regular.otf') format('truetype');
    src: url('../fonts/corporates/CorporateS-Regular.eot');
    src: url('../fonts/corporates/CorporateS-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/corporates/CorporateS-Regular.woff2') format('woff2'),
      url('../fonts/corporates/CorporateS-Regular.woff') format('woff'),
      url('../fonts/corporates/CorporateS-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../fonts/inter/Inter-Thin.otf') format('truetype');
    src: url('../fonts/inter/Inter-Thin.eot');
    src: url('../fonts/inter/Inter-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-Thin.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-Thin.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-Thin.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../fonts/inter/Inter-ThinItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-ThinItalic.eot');
    src: url('../fonts/inter/Inter-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-ThinItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-ThinItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-ThinItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('../fonts/inter/Inter-ExtraLight.otf') format('truetype');
    src: url('../fonts/inter/Inter-ExtraLight.eot');
    src: url('../fonts/inter/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-ExtraLight.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-ExtraLight.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-ExtraLight.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('../fonts/inter/Inter-ExtraLightItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-ExtraLightItalic.eot');
    src: url('../fonts/inter/Inter-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-ExtraLightItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-ExtraLightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/inter/Inter-Light.otf') format('truetype');
    src: url('../fonts/inter/Inter-Light.eot');
    src: url('../fonts/inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-Light.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-Light.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/inter/Inter-LightItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-LightItalic.eot');
    src: url('../fonts/inter/Inter-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-LightItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-LightItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/inter/Inter-Regular.otf') format('truetype');
    src: url('../fonts/inter/Inter-Regular.eot');
    src: url('../fonts/inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-Regular.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-Regular.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/inter/Inter-Italic.otf') format('truetype');
    src: url('../fonts/inter/Inter-Italic.eot');
    src: url('../fonts/inter/Inter-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-Italic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-Italic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/inter/Inter-Medium.otf') format('truetype');
    src: url('../fonts/inter/Inter-Medium.eot');
    src: url('../fonts/inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-Medium.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-Medium.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/inter/Inter-MediumItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-MediumItalic.eot');
    src: url('../fonts/inter/Inter-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-MediumItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-MediumItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-MediumItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/inter/Inter-SemiBold.otf') format('truetype');
    src: url('../fonts/inter/Inter-SemiBold.eot');
    src: url('../fonts/inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-SemiBold.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-SemiBold.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-SemiBold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/inter/Inter-SemiBoldItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-SemiBoldItalic.eot');
    src: url('../fonts/inter/Inter-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-SemiBoldItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-SemiBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/inter/Inter-Bold.otf') format('truetype');
    src: url('../fonts/inter/Inter-Bold.eot');
    src: url('../fonts/inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-Bold.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-Bold.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/inter/Inter-BoldItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-BoldItalic.eot');
    src: url('../fonts/inter/Inter-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-BoldItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-BoldItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-BoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/inter/Inter-ExtraBold.otf') format('truetype');
    src: url('../fonts/inter/Inter-ExtraBold.eot');
    src: url('../fonts/inter/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-ExtraBold.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-ExtraBold.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-ExtraBold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/inter/Inter-ExtraBoldItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-ExtraBoldItalic.eot');
    src: url('../fonts/inter/Inter-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-ExtraBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/inter/Inter-Black.otf') format('truetype');
    src: url('../fonts/inter/Inter-Black.eot');
    src: url('../fonts/inter/Inter-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-Black.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-Black.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-Black.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/inter/Inter-BlackItalic.otf') format('truetype');
    src: url('../fonts/inter/Inter-BlackItalic.eot');
    src: url('../fonts/inter/Inter-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/inter/Inter-BlackItalic.woff2?v=3.19') format('woff2'),
      url('../fonts/inter/Inter-BlackItalic.woff?v=3.19') format('woff'),
      url('../fonts/inter/Inter-BlackItalic.ttf') format('truetype');
  }

  /* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  /* roboto-300italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  /* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  /* roboto-italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  /* roboto-500italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  /* roboto-700italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg#Roboto')
        format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: RobotoFallback;
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'),
      url('~devextreme/dist/css/fonts/Roboto-300.woff2') format('woff2'),
      url('~devextreme/dist/css/fonts/Roboto-300.woff') format('woff'),
      url('~devextreme/dist/css/fonts/Roboto-300.ttf') format('truetype');
  }

  @font-face {
    font-family: RobotoFallback;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
      url('~devextreme/dist/css/fonts/Roboto-400.woff2') format('woff2'),
      url('~devextreme/dist/css/fonts/Roboto-400.woff') format('woff'),
      url('~devextreme/dist/css/fonts/Roboto-400.ttf') format('truetype');
  }

  @font-face {
    font-family: RobotoFallback;
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'),
      url('~devextreme/dist/css/fonts/Roboto-500.woff2') format('woff2'),
      url('~devextreme/dist/css/fonts/Roboto-500.woff') format('woff'),
      url('~devextreme/dist/css/fonts/Roboto-500.ttf') format('truetype');
  }

  @font-face {
    font-family: RobotoFallback;
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'),
      url('~devextreme/dist/css/fonts/Roboto-700.woff2') format('woff2'),
      url('~devextreme/dist/css/fonts/Roboto-700.woff') format('woff'),
      url('~devextreme/dist/css/fonts/Roboto-700.ttf') format('truetype');
  }

  @font-face {
    font-family: DXIcons;
    src: local('DevExtreme Material Icons'), local('devextreme_material_icons'),
      url('~devextreme/dist/css/icons/dxiconsmaterial.woff2') format('woff2'),
      url('~devextreme/dist/css/icons/dxiconsmaterial.woff') format('woff'),
      url('~devextreme/dist/css/icons/dxiconsmaterial.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
}
