header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: white;
  border-bottom: 1px solid $napp-color-neutral-light;
  height: $napp-header-height;
  margin-top: calc(-1 * var(--sat));
  margin-left: calc(-1 * var(--sal));
  margin-right: calc(-1 * var(--sar));

  .logo-header-container {
    /*margin-left: 20px;*/

    .navbar-brand {
      font-size: 1.5rem;
      margin-right: 0;
      padding-top: $napp-header-logo-padding-y !important;
      padding-bottom: $napp-header-logo-padding-y !important;
    }
    a {

      .logo-header {
        max-height: $napp-header-logo-size + 2 * $napp-header-logo-padding-y;
        font-size: $napp-header-logo-size;
        height: 1em;
        width: (
          calc(map-get($napp-header-logo, "width") * 1em / map-get($napp-header-logo, "height"))
        );
        background-image: url("/assets/themes/#{map-get($napp-header-logo, "url")}");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      color: initial;
    }
  }

  .navbar {
    padding-left: var(--sal);
    padding-right: var(--sar);

    .dx-button:not(.user-icon) {
      border-radius: 50% !important;
    }

    .header-profile-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
    }

    .dx-button .dx-button-content {
      border-radius: 50%;
    }
  }
}
