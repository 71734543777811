.comments {
  color: napp-theme-color('neutral', 'dark');
  h5, .h5 {
    font-size: $napp-body-font-size;
  }

  .row {
    margin-bottom: 40px;

    &.comment-form {
      padding-top: 10px;
      padding-bottom: 10px;
      box-shadow:
        0px 11px 8px -10px #CCC,
        0px -11px 8px -10px #CCC;

      .dx-texteditor.dx-editor-underlined:after,
      .dx-texteditor.dx-editor-filled:after {
        border-bottom: 0;
      }
    }

    &.comment-row {
      app-renderer {
        display: inline-block;
      }
    }
  }

  .author-image {
    margin-right: 20px;
  }

  .highlightComment {
    background-color: comment-highlight-color($color: napp-theme-color('primary', 'light'));
  }
}

.feed .comment-row .post-author-image-container,
.feed .comment-form .post-author-image-container{
  margin-right: 10px;
}

.comments .post-author-image-container .post-author-image {
  width: 50px;
  height: 50px;
}
