@use 'sass:color';

// Logos
$napp-header-logo: (
  'width': 110,
  'height': 65,
  'url': 'custom/images/header.svg',
) !default;
$napp-header-logo-size: 50px !default;
$napp-header-logo-padding-y: 0px !default;

$napp-logos: (
  'brand': (
    'url': 'custom/images/brand.svg',
    'width': 65,
    'height': 65,
    'color': #334752,
  ),
  'brand-text': (
    'url': 'custom/images/brand-text.svg',
    'width': 110,
    'height': 65,
    'color': #334752,
  ),
) !default;

$napp-user-image: (
  'url': 'custom/images/user.png',
) !default;

// Colors
$napp-color-primary: rgb(51, 71, 82) !default;
$napp-color-lighten-10: lighten($napp-color-primary, 10) !default;
$napp-color-lighten-20: lighten($napp-color-primary, 20) !default;
$napp-color-darken-35: darken($napp-color-primary, 35) !default;

$napp-color-white: rgb(255, 255, 255) !default;
$napp-color-ultra-light-gray: rgb(249, 249, 249) !default;
$napp-color-gray: rgb(134, 134, 134) !default;
$napp-color-neutral-light: #f3fecf !default;
$napp-color-secondary: rgb(200, 255, 0) !default;
$napp-color-warn-light: rgb(255, 128, 128) !default;
$napp-color-light-gray: rgb(168, 168, 168) !default;
$napp-color-warn-dark: rgb(216, 91, 91) !default;
$napp-color-liked-post: rgb(186, 196, 5) !default;
$napp-color-bottom-navigation-bg: $napp-color-neutral-light !default;
$napp-color-bottom-navigation-inactive: rgb(230, 254, 157) !default;
$napp-color-bottom-navigation-active: $napp-color-primary !default;
$napp-color-sidebar-logo-background: $napp-color-secondary !default;

$font-family-regular: 'Inter', Roboto, 'Helvetica Neue', Arial, sans-serif !default;
$font-family-bold: $font-family-regular !default;
$napp-body-font-family: 'Inter', Roboto, 'Helvetica Neue', Arial, sans-serif !default;
$napp-body-font-size: 1rem !default;
$napp-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 4px 20px 0 rgba(0, 0, 0, 0.1) !default;

$napp-body-text-color: $napp-color-primary !default;
$napp-body-text-color-dark-bg: $napp-color-secondary !default;
$napp-body-text-color-inactive: color.scale(color.change($napp-body-text-color, $saturation: 0%), $lightness:  45%) !default;
$napp-body-text-color-inactive-dark-bg: color.scale(color.change($napp-body-text-color-dark-bg, $saturation: 0%), $lightness: -45%) !default;

$napp-text-dark-threshold: 50 !default;

// Theme Colors
$napp-theme-colors: (
  primary: (
    light: $napp-color-lighten-20,
    base: $napp-color-darken-35,
  ),
  secondary: (
    base: $napp-color-secondary,
  ),
  neutral: (
    base: $napp-color-white,
    light: $napp-color-neutral-light,
    dark: $napp-color-gray,
  ),
  indication: (
    danger: $napp-color-warn-dark,
  ),
) !default;

// General Typography
$napp-subheadline-size: 1.2rem !default;
$napp-header-font-size: 1.1rem !default;
$napp-small-font-size: 0.8rem !default;
$napp-button-font-size: 0.8rem !default;

// Form
$napp-form-font-size: 1rem !default;

// Components
$napp-border-radius: 4px !default;

// Measures
$napp-header-height: 60px !default;

// Sidebar
$napp-sidebar-desktop-width: 340px !default;
$napp-sidebar-mobile-width: 85% !default;

// Spacer
$napp-spacer-vertical: 20px !default;

$bottombar-height: 0px !default; /*60px;*/
$page-actions-bottom-margin: 10px !default;

$napp-popup-fullscreen-threshold-size: 450px !default;
