.employee-list {
  .employee-card {
    height: 200px;
    overflow: hidden;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 4px 20px 0 rgba(0, 0, 0, 0.1);

    .author-image-container {
      height: 100px;
      width: 100px;
      border: 1px solid #dee2e6;
      border-radius: 50%;
    }
  }
}
