.dx-toolbar {
  .dx-toolbar-button .dx-button-has-text:not(.dx-button-back) .dx-icon {
    line-height: 24px;
  }

  &.toolbar-comment {
    .dx-toolbar-items-container {
      height: auto;
    }
    display: inline-block;
    width: auto;
  }

  .dx-toolbar-before {
    .dx-toolbar-button {
      .dx-button-has-icon {
        color: black;
      }
    }
  }
}
