$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  2xl: 1560px,
  3xl: 1920px,
  4xl: 2160px,
  5xl: 2400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  2xl: 1500px,
  3xl: 1860px,
  4xl: 2100px,
  5xl: 2340px,
);
