.mm-menu {
  span.title {
    font-family: $font-family-bold;
    font-weight: bold;
    margin-left: -15px;
    margin-top: 25px;
  }

  span.title:first-child {
    margin-top: 0;
  }

  .mm-listitem,
  .mm-navbar {
    border: none;

    &:after {
      border: none;
    }
  }

  .menu-divider{
    height: 1px;
    background: $napp-color-primary;
    margin: 0.5rem 0;
    padding: 0;

    a {
      display: none;
    }
  }

  .mm-navbar {
    font-family: $font-family-bold;
    font-weight: bold;
  }

  .mm-listview {
    margin-left: 15px;
    padding: 0;
  }

  a.active,
  a:hover,
  a:focus {
    color: $napp-color-primary;
  }

  .mm-listitem__text {
    padding-top: 10px;
    padding-bottom: 10px;

    max-width: 230px;
  }

  .mm-navbar__title {
    display: none;
  }

  .mm-btn_prev {
    margin-top: 20px;
  }

  .mm-more-icons {
    position: absolute;
    right: 45px;
    line-height: 20px;
    padding: 10px;
    cursor: pointer;
    background-color: white;
  }

  .mmenu-site-actions {
    position: absolute;
    z-index: 9999;
    right: 7px;
    top: 40px;
    width: 230px;
    background-color: $napp-color-white;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    padding: 1px;

    li.site-action {
      padding: 14px 15px;
      cursor: pointer;

      &:first-of-type {
        margin-top: 8px;
      }

      &:last-of-type {
        margin-bottom: 8px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      i {
        width: 40px;
        height: 24px;
        vertical-align: bottom;
        line-height: 24px;
      }
    }
  }
}

#left-menu {
  width: 90%;
  margin: 0 auto;
  background: $napp-color-white;
  padding-top: 60px;
  padding-top: calc(60px + var(--sat));
  padding-left: var(--sal);

  .mm-panel,
  .mm-panels,
  .mm-navbar {
    background: $napp-color-white;
  }

  .mm-panel,
  .mm-panels,
  .mm-navbar,
  .mm-listitem {
    color: $napp-color-gray;
    text-transform: uppercase;
  }
}

.right-menu {
  background: $napp-color-white;
  margin: 0 auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 60px 17px 0 17px;
  padding-top: calc(60px + var(--sat));
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @supports(padding: unquote('max(0px)')) {
    padding-right: unquote('max(17px, var(--sar))');
  }

  .sidebar-right-content,
  .sidebar-right-content-no-scroll {
    margin-top: 80px;
    padding-bottom: 50px;
    position: relative;
    height: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .sidebar-right-content {
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .sidebar-right-content,
  .sidebar-right-content-no-scroll {
    ul {
      list-style: none;
      padding-left: 0;

      &.separator {
        border-top: 1px solid;
        border-top-color: rgba($napp-color-darken-35, 0.3);
        padding-top: 10px;
        margin-bottom: 10px;
      }

      .list-item {
        color: $napp-color-darken-35;
        list-style: none;
        display: block;
        padding: 0;
        margin: 0;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;



        &.inactive {
          a {
            color: napp-theme-color('primary', 'light')
          }
        }

        a i {
          min-width: 22px;
        }

        a.active,
        a:hover,
        a:focus {
          text-decoration: none;
        }

        i.upper-icon {
          font-size: 8px;

          &:before {
            position: absolute;
            top: 10px;
          }
        }

        .title {
          color: $napp-color-primary;
          margin-top: 25px;
        }

        .text {
          margin-left: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
          color: $napp-color-primary;
        }
      }
    }

    .title {
      color: $napp-color-primary;
    }

    .dx-form {
      margin-bottom: 15px;

      label {
        margin-bottom: 0;
      }
    }

    a {
      color: $napp-color-darken-35;
    }

    .arrow-left-menu {
      right: 0;
      transition: right 0.2s;
    }

    .arrow-left-menu:hover {
      right: 5px;
    }

    .registration-completed {
      text-align: center;

      p {
        color: $napp-color-primary;
        margin: 0;
      }
    }

    .dx-button.dx-button-default.dx-button-mode-contained {
      width: 100%;
      margin: 10px 0;
    }
  }
}

.dx-fa-button .dx-overlay-wrapper {
  z-index: 2000 !important;
}
