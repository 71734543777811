@mixin card {
  .card-columns {
    @include media-breakpoint-only(md)  {
      column-count: 1 !important;
    }
    @include media-breakpoint-only(lg)  {
      column-count: 2 !important;
    }
    @include media-breakpoint-only(xl) {
      column-count: 2 !important;
    }
  }

  .card-header {
    min-height: 100px;
    background-color: $napp-color-white !important;
  }

  .no-style-card .card {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border: 0 !important;
    box-shadow: $napp-box-shadow;
    margin: 60px auto 0 auto !important;
    max-width: 750px;
  }

  .card-body {
    position: relative;
  }

  .card-body .user-info {
    position: absolute;
    top: -17%;
    left: 0;
    width: 100%;
    height: 150px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .card-user-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .card-footer {
    padding: 0;

    .dx-button {
      width: 100%;
    }
  }

  .badge.badge-pill {
    display: none;
  }
}
