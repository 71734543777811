.post-content {
  position: relative;
}
.go-back-arrow {
  position: absolute;
  right: 20px;
  top: 16px;
  font-size: 28px;
}

#block-system-main {
  padding: 20px;
}

#block-system-main img {
  max-height: 50px;
}

.table tr:nth-child(odd), .view_Game .table tr:nth-child(odd) {
  color: #fff;
  background-color: #e21d24 !important;
  border-bottom: 1px solid #1c4266 !important;
}

.table tr:nth-child(even), .view_Game .table tr:nth-child(even) {
  background-color: #fff !important;
  border-bottom: 1px solid #fff !important;
}

.table th, .view_Game .table th {
  color: #1c4266;
  background-color: #fff !important;
  font-size: 16px !important;
}

.views-table .odd a {
  color: white;
  text-decoration: underline;
}

.views-table .even a {
  color: #e21d24;
  text-decoration: underline;
}

.odd .table>thead>tr>th, .table>tbody>tr>th,
.odd .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td,
.even .table>thead>tr>th, .table>tbody>tr>th,
.even .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

@media (max-width: 768px) {
  .table .views-field-field-game-hometeam, .table .views-field-field-game-hometeam {
    display: none;
  }

  .table .views-field-field-game-awayteam, .view_Game .table .views-field-field-game-awayteam {
    display: none;
  }

  .table .views-field-field-game-qualification, .view_Game .table .views-field-field-game-qualification {
    display: none;
  }

  .views-table {
    width: 100%;
  }
}
