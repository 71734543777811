app-cms-site {
  a {
    &:not(.post-action > *)::before {
      content: "\f35d";
      font-family: 'Font Awesome\ 5 Free', sans-serif;
      font-weight: 900;
      margin-right: 7px;
    }
    &.is-pdf::before {
      content: "\f1c1";
    }
  }

  table {
    border: none transparent;
    width: 100% !important;

    tbody tr {
      border-bottom: 1px solid #ddd;

      &:first-child {
        border-bottom: 3px solid #ddd;
      }

      &:nth-child(even) {
        background-color: #f6f6f6;
      }
    }
  }
}
