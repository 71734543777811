@import 'core/bootstrap_variables';

// Bootstrap Styles
@import '~bootstrap/scss/bootstrap';

// FontAwesome
@import '~@fortawesome/fontawesome-free/css/all.css';

// SCSS Functions
@import 'core/helpers/functions';

// Core Styles
@import 'core/variables';
@import 'core/fonts';

/* mirror all variables, such that they can be accessed from javascript */
:root {
  --napp-color-primary: #{$napp-color-primary};
  --napp-color-lighten-10: #{$napp-color-lighten-10};
  --napp-color-lighten-20: #{$napp-color-lighten-20};
  --napp-color-darken-35: #{$napp-color-darken-35};

  --napp-color-white: #{$napp-color-white};
  --napp-color-ultra-light-gray: #{$napp-color-ultra-light-gray};
  --napp-color-gray: #{$napp-color-gray};
  --napp-color-neutral-light: #{$napp-color-neutral-light};
  --napp-color-secondary: #{$napp-color-secondary};
  --napp-color-warn-light: #{$napp-color-warn-light};
  --napp-color-light-gray: #{$napp-color-light-gray};
  --napp-color-warn-dark: #{$napp-color-warn-dark};
  --napp-color-liked-post: #{$napp-color-liked-post};
  --napp-color-bottom-navigation-bg: #{$napp-color-bottom-navigation-bg};
  --napp-color-bottom-navigation-inactive: #{$napp-color-bottom-navigation-inactive};
  --napp-color-bottom-navigation-active: #{$napp-color-bottom-navigation-active};
  --napp-color-sidebar-logo-background: #{$napp-color-sidebar-logo-background};

  --font-family-regular: #{$font-family-regular};
  --font-family-bold: #{$font-family-bold};
  --napp-body-font-family: #{$napp-body-font-family};
  --napp-body-font-size: #{$napp-body-font-size};
  --napp-box-shadow: #{$napp-box-shadow};

  --napp-body-text-color: #{$napp-body-text-color};
  --napp-body-text-color-dark-bg: #{$napp-body-text-color-dark-bg};
  --napp-body-text-color-inactive: #{$napp-body-text-color-inactive};
  --napp-body-text-color-inactive-dark-bg: #{$napp-body-text-color-inactive-dark-bg};

  // Theme Colors
  --napp-theme-colors-primary-light: #{map-get(map-get($napp-theme-colors, 'primary'), 'light')};
  --napp-theme-colors-primary-base: #{map-get(map-get($napp-theme-colors, 'primary'), 'base')};
  --napp-theme-colors-secondary-base: #{map-get(map-get($napp-theme-colors, 'secondary'), 'base')};
  --napp-theme-colors-neutral-base: #{map-get(map-get($napp-theme-colors, 'neutral'), 'base')};
  --napp-theme-colors-neutral-light: #{map-get(map-get($napp-theme-colors, 'neutral'), 'light')};
  --napp-theme-colors-neutral-dark: #{map-get(map-get($napp-theme-colors, 'neutral'), 'dark')};
  --napp-theme-colors-indication-danger: #{map-get(map-get($napp-theme-colors, 'indication'), 'danger')};

  // General Typography
  --napp-subheadline-size: #{$napp-subheadline-size};
  --napp-header-font-size: #{$napp-header-font-size};
  --napp-small-font-size: #{$napp-small-font-size};
  --napp-button-font-size: #{$napp-button-font-size};

  // Form
  --napp-form-font-size: #{$napp-form-font-size};

  // Components
  --napp-border-radius: #{$napp-border-radius};

  // Measures
  --napp-header-height: #{$napp-header-height};

  // Sidebar
  --napp-sidebar-desktop-width: #{$napp-sidebar-desktop-width};
  --napp-sidebar-mobile-width: #{$napp-sidebar-mobile-width};

  // Spacer
  --napp-spacer-vertical: #{$napp-spacer-vertical};

  --bottombar-height: #{$bottombar-height};
  --page-actions-bottom-margin: #{$page-actions-bottom-margin};
  --napp-popup-fullscreen-threshold-size: #{$napp-popup-fullscreen-threshold-size};
}

@import 'core/layout';
@import 'core/logos';
@import 'core/navigation/footernav';
@import 'core/header';
@import 'core/footer';
@import 'core/sidebars';
@import 'core/navigation/menu';
@import 'core/card';
@import 'core/tags';
@import 'core/buttons';
@import 'core/sponsors';
@import 'core/result';
@import 'core/form';
@import 'core/accordion';
@import 'core/datagrid';
@import 'core/loadingbar';
@import 'core/feed';
@import 'core/comment';
@import 'core/shortener';
@import 'core/toolbar';
@import 'core/profile';
@import 'core/filemanager';
@import 'core/popup';
@import 'core/scrollview';
@import 'core/tabs';
@import 'core/list';
@import 'core/magazine';
@import 'core/cmssite';
@import 'core/tinymce';
@import 'core/employee';

// Responsive Overwrites
// more information: // see: https://getbootstrap.com/docs/4.3/layout/overview/
// desktop
@import 'desktop/index';
