.popup-container {
  padding: 20px;
  padding-bottom: 0px;
  padding-bottom: var(--sab);
}

.popup-title {
  padding: 20px 15px;
  overflow: visible;
  box-shadow: none;
  position: relative;
  margin-bottom: -4px;
  min-height: 28px;
  border-bottom: 1px solid #e0e0e0;
  font-size: $napp-header-font-size;
}

.dx-popup-content {
  padding: 20px;

  .dx-template-wrapper {
    position: relative;
  }
}

.dx-popup-normal .dx-toolbar-center {
  margin: 0 !important;
}
.dx-popup-normal {
  .dx-toolbar-label > div {
    padding: 0;
  }
}

@media (max-width: $napp-popup-fullscreen-threshold-size) {
  .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: $napp-header-height;
  }
}
