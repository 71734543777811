app-shortener-edit-shortening {
  .clickTemplate {
    line-height: 2.2em;
    text-align: center;
    width: 100%;
    display: block;
  }

  .border-editing {
    border: 2px solid napp-theme-color(primary, base);
    border-radius: 0.25rem !important;
  }
}

.list-shortenings {
  .shortening-container{
    padding-left: 0 !important;
  }
}

.shortener-shortening {

  &.card {
    border-radius: 0.25rem !important;
    margin: 1rem 0 !important;
  }

  .dx-layout-manager .dx-field-item:not(.dx-last-col) {
    padding-right: 0;
  }
  .dx-layout-manager .dx-field-item:not(.dx-first-col) {
    padding-left: 0;
  }

  position: relative;
  .drop-down-menu {
    top: 0;
    right: 0;
    position: absolute;
  }

  &.shortener-placeholder {
    align-items: center;
    background: napp-theme-color('neutral', 'light');
    color: napp-theme-color('neutral', 'dark');
    font-size: 1rem;
    cursor: pointer;

    i {
      margin: 8px 20px;
      font-size: 25px;
    }
  }
}
