@-webkit-keyframes slideIn{
  0% {left: 100%;}
  1% {left: 0;}
  14% {left: 0;}
  15% {left: -100%;}
  100%{left: -100%;}
}

@keyframes slideIn{
  0% {left: 100%;}
  1% {left: 0;}
  14% {left: 0;}
  15% {left: -100%;}
  100%{left: -100%;}
}

.sponsors-category {
  -webkit-animation: slideIn 35s linear infinite;
  animation: slideIn 35s linear infinite;
}

.sponsors-category:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sponsors-category:nth-of-type(2) {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.sponsors-category:nth-of-type(3) {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
}
.sponsors-category:nth-of-type(4) {
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
}
.sponsors-category:nth-of-type(5) {
  -webkit-animation-delay: 20s;
  animation-delay: 20s;
}
.sponsors-category:nth-of-type(6) {
  -webkit-animation-delay: 25s;
  animation-delay: 25s;
}
.sponsors-category:nth-of-type(7) {
  -webkit-animation-delay: 30s;
  animation-delay: 30s;
}

.sponsors-container-banner {
  position: relative;
  height: 200px;
  overflow: hidden;
  margin-top: 3rem;

  .sponsors-category {
    position: absolute;
    padding: 0 1rem;
    width: 100%;
    left: 100%;

      .sponsors {
        padding: 1rem 0;
      }

      .sponsor {
        height: 5rem;
        padding: 0.5rem 0.5rem;
        width: calc(100% / 2 - 1rem);
        -webkit-box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.25);
        box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.25);
      }
  }
}
