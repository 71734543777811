.feed {
  a {
    outline: none;
    color: $napp-color-gray;
  }

  .description-text {
    a:not(.dx-mention)::before {
      content: '\f35d';
      font-family: 'Font Awesome\ 5 Free', sans-serif;
      font-weight: 900;
      margin-right: 7px;
    }

    a:not(.dx-mention).is-pdf::before {
      content: '\f1c1';
    }
  }

  &.feed-list {
    display: block;

    &:not(.active) .feed-posts,
    .has-subscriptions-menu {
      display: none !important;
    }

    .feed-list-tags {
      margin-top: 15px;

      span {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 7px;
        padding: 5px;
        border: none;
        border-radius: 5px;
        background-color: napp-theme-color('primary', 'light');
        color: legible-text-color(napp-theme-color('primary', 'light'));
        line-height: 24px;
        cursor: pointer;
      }
    }
  }

  .feed-post.post-native {
    margin-top: 50px !important;
  }

  .post-teaser-image-container {
    position: relative;
    min-height: 150px;
    z-index: 6;
  }

  .post-teaser-image-link {
    cursor: pointer;
  }

  .author {
    position: absolute;
    display: block;
    width: 100%;
    top: -35px;
    text-align: center;
    margin-left: -20px;
    font-size: $napp-small-font-size;
    margin-bottom: 20px;
    color: napp-theme-color('neutral', 'dark');
  }

  .post-author-image-container {
    text-align: center;

    .post-author-image {
      height: 75px;
      width: 75px;
      display: inline-block;
    }
  }

  .description {
    margin-top: 80px;
    min-height: 150px;

    .description-text {
      color: napp-theme-color('neutral', 'dark');
      margin-bottom: 20px;
      white-space: pre-wrap;
    }

    .more-button {
      border: none;
      outline: none;
      background: none;
      padding: 0;
      margin: 0;
      // display: block;
      // font-family: 'Font Awesome\ 5 Free', sans-serif;
      color: #868686;
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .description {
      min-height: 170px;
    }
  }

  @media screen and (max-width: 300px) {
    .description {
      min-height: 250px;
    }
  }

  .feed-post-item {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid napp-theme-color('neutral', 'light');
  }

  .post-type-label {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: napp-theme-color('primary', 'light');
    padding: 0 10px;
    color: legible-text-color(napp-theme-color('primary', 'light'));
    text-transform: uppercase;
    z-index: 8;
    cursor: pointer;
    font-size: $napp-small-font-size;
  }

  .post-description-append {
    margin-top: 20px;
  }

  .post-show-likes {
    color: $napp-color-gray;
    padding-inline: 10px;

    i {
      cursor: pointer;
    }
  }

  .post-actions {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .post-like,
  .post-action,
  .post-report {
    color: napp-theme-color('neutral', 'dark');
    font-weight: bold;

    &.liked i {
      color: $napp-color-liked-post;
    }

    i {
      cursor: pointer;
      color: napp-theme-color('secondary', 'base');
    }

    &.disabled {
      i {
        cursor: default;
      }
    }
  }

  .post-views {
    padding-right: 10px;
    color: $napp-color-gray;
    font-weight: bold;
  }

  .post-report {
    margin-top: -6px;

    i {
      color: napp-theme-color('neutral', 'dark');
    }
  }

  .post-share {
    i {
      color: napp-theme-color('neutral', 'dark');
    }
  }

  .related-posts {
    background-color: napp-theme-color('neutral', 'light');
    margin-bottom: -60px;
    padding-bottom: 60px;

    .card {
      border-radius: 0 !important;
      padding: 15px;
      margin-bottom: 25px !important;
      cursor: pointer;

      .napp-card-header {
        .card-header {
          background: transparent;
        }
      }

      .napp-card-body {
        display: none;
      }

      .napp-card-footer {
        padding-bottom: 0 !important;

        .card-footer {
          padding: 0 !important;

          hr {
            margin: 0 50px 0 0px;
          }

          .author-image-container {
            display: inline-block;
            width: inherit;

            i {
              font-size: 20px;
            }

            img {
              position: inherit;
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }

  .feed-unpublished {
    color: napp-theme-color(indication, danger);
    font-size: 0.8em;
    font-weight: bold;
  }
}

.empty-feed {
  margin-top: 40px;
}

.filter {
  font-size: $napp-small-font-size;
  margin: 10px 0;
  display: block;

  .active {
    .dx-button.dx-button-normal.dx-button-mode-text {
      color: napp-theme-color(primary, light);
    }
  }

  .dx-field {
    display: flex;

    &:before {
      content: none;
    }
  }

  .dx-field .dx-field-label {
    margin-bottom: 0;
    width: 100px !important;

    &.dx-field-label-boolean {
      width: 200px !important;
    }
  }

  .dx-field .dx-field-value:not(.w-100) {
    width: auto !important;
  }

  .dx-field .dx-field-label,
  .dx-field .dx-field-value {
    float: none !important;
  }

  .dx-placeholder,
  .dx-texteditor-input {
    font-size: $napp-small-font-size;
  }
}

.share-popup {
  .share-logo {
    font-size: 50px;
    margin: 5px 30px 5px 0;
    color: white;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
    min-width: 70px;
    transition: transform 0.2s ease;

    &.facebook {
      background-color: #4267b2;
    }

    &.twitter {
      background-color: #1da1f2;
    }

    &.instagram {
      background-image: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    }

    &.linkedin {
      background-color: #2867b2;
    }

    &.xing {
      background-color: #006567;
    }

    &.email {
      background-color: #f39c12;
    }

    &.active,
    &:hover {
      transform: scale(0.85);
    }

    i.checked {
      background-color: napp-theme-color(primary, base);
      color: white;
      position: absolute;
      padding: 5px;
      border-radius: 50%;
      top: -10px;
    }
  }
}

agm-map {
  height: 300px;
}

.feed-post-card__author {
  position: relative;
}

.feed-post-card__author__content {
  position: absolute;
  top: -100px;
  width: 100%;
  text-align: center;
}

.post-action-group {
  flex: 1;
}
