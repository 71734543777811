app-file-manager,
.file-manager,
.file-manager .dx-tabpanel,
app-file-upload,
.file-upload {
  height: 100%;
  display: block;
}

.popup-container.file-manager {
  padding: 0;
  margin-bottom: var(--sab);
}

ul.file-browser {
  padding-left: 0;

  li {
    position: relative;
    float: left;
    padding: 8px;
    margin: 0;
    color: #444;
    cursor: pointer;
    list-style: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 50%;
    max-width: 200px;
    box-sizing: border-box;

    .attachment-preview {
      position: relative;
      background-color: napp-theme-color(neutral, light);
      cursor: pointer;

      i.checked {
        background-color: napp-theme-color(primary, base);
        color: white;
        position: absolute;
        right: 3px;
        padding: 5px;
        border-radius: 50%;
        top: 3px;
      }

      &.active {
        background-color: napp-theme-color(primary, light);
      }

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .thumbnail {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        transition: opacity 0.1s;

        .centered {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            max-height: 100%;
            transition: transform 0.2s ease, opacity 1s ease;

            &.loaded {
              opacity: 1;
            }

            &:hover,
            &.active {
              transform: scale(0.85);
            }
          }

          .preview-wrapper {
            img {
              opacity: 1 !important;
              object-fit: contain;
              background-color: white;
            }

            &.active {
              img {
                transform: scale(0.85);
              }
            }
          }
        }
      }
    }
  }
}

.file-upload {
  position: relative;

  image-cropper {
    text-align: center;
    padding: 10px 0 50px;
    height: 60vh;
    height: calc(80vh - 5 * #{$napp-header-height});
    height: calc(80vh - 5 * #{$napp-header-height} - var(--sab));
  }

  @media (max-width: $napp-popup-fullscreen-threshold-size) {
    image-cropper {
      height: 50vh;
      height: calc(100vh - 4 * #{$napp-header-height} - 15px);
      height: calc(100vh - 4 * #{$napp-header-height} - 15px - var(--sab));
    }
  }

  .upload-zone {
    text-align: center;

    .file-input {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .hide {
      visibility: hidden;
    }

    .dx-fileuploader-input-wrapper .dx-button {
      float: none;
    }

    .dx-fileuploader-file-status-message {
      display: none;
    }

    .dx-fileuploader-files-container .dx-fileuploader-file-container {
      padding: 15px;
    }

    .dx-fileuploader-wrapper {
      padding: 0 7px;
    }

    .dx-progressbar {
      padding: 0 10px;
    }
  }
}

.file-selection {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  aspect-ratio: 15 / 7;
  display: flex;
  background-color: napp-theme-color(neutral, light);
  text-align: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  i.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    margin: -10px;
  }

  .post-teaser-image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s ease;
    // background-color: napp-theme-color(neutral, light);

    .icon-overlay {
      color: $napp-color-primary;
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }

    .teaser-image-icon {
      color: white;
      text-shadow: 0 0 5px #444;
      top: 0;
      right: 10px;
      left: auto;
      transform: none;
      -ms-transform: none;
      opacity: 1;
    }
  }

  .post-teaser-image-overlay {
    opacity: 0.6;
  }

  @media (hover: hover) {
    .post-teaser-image-overlay {
      opacity: 0.2;
    }

    &:hover .post-teaser-image-overlay {
      opacity: 0.6;
    }
  }

  &.file-selection-fluid {
    width: 100%;
    height: auto;
  }
}
