@mixin layout {

  body.sidebar-open {
    overflow: auto;
  }

  #content.left {
    margin-left: $napp-sidebar-desktop-width;
    margin-right: calc(-1 * #{$napp-sidebar-desktop-width});
    transition: all 0.3s ease-in-out;

    .page-title {
      margin-left: 0;
    }

    #bottom-navigations {
      left: $napp-sidebar-desktop-width;
      right: calc(-1 * #{$napp-sidebar-desktop-width});
      transition: all 0.3s ease-in-out;
    }
  }

  #main-page-wrapper .child-layer{
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }

    &.mobile-full-width {
      .container {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  #content.right {
    margin-right: $napp-sidebar-desktop-width;
    margin-left: calc(-1 * #{$napp-sidebar-desktop-width});
    transition: all 0.3s ease-in-out;

    #bottom-navigations {
      right: $napp-sidebar-desktop-width;
      left: calc(-1 * #{$napp-sidebar-desktop-width});
      transition: all 0.3s ease-in-out;
    }
  }

  #content.left,
  #content.right {
    overflow-y: inherit !important;
    pointer-events: all !important;
  }

  #main-page-wrapper {

    &.container {
      padding-right: 15px;
      padding-left: 15px;
    }

    app-page {
      .page-actions-container {
        .page-actions {
          padding-bottom: $page-actions-bottom-margin;
        }
      }
    }
  }
}
