.dx-accordion {

  margin-left: 0;
  margin-right: 0;
  font-family: $napp-body-font-family;
  font-size: 1rem;

  .dx-accordion-item {
    box-shadow: none;

    .dx-accordion-item-title {
      background-color: $napp-color-white !important;
      padding: 12px;
      &:before {
        right: 12px;
      }
    }

    .dx-accordion-item-title-caption {
      font-weight: bold;
      left: 12px;
      font-size: 1rem;
    }

    .dx-accordion-item-body {
      padding: 12px;
      font-size: inherit;
    }
  }

  .accordion-panel-footer-buttons {
    margin-top: $napp-spacer-vertical;
    text-align: right;
  }

  .dx-empty-message {
    font-size: $napp-small-font-size;
  }

  .dx-accordion-item-title {
    font-size: $napp-body-font-size;
    padding-left: 0;
    padding-right: 0;
  }

  .dx-accordion-item-body {
    padding-left: 0;
    padding-right: 0;
  }

  .dx-accordion-item-opened {
    margin: 0;
    border-top: 1px solid #eaeaea;
  }

  .dx-accordion-item-opened > .dx-accordion-item-title {
    height: 48px;
  }

  .dx-accordion-item-opened > .dx-accordion-item-title .dx-accordion-item-title-caption {
    top: 16px;
  }

  .dx-accordion-item-opened > .dx-accordion-item-title:before {
    top: 11px;
  }

  .dx-accordion-item.dx-state-hover > .dx-accordion-item-title,
  .dx-accordion-item.dx-state-focused > .dx-accordion-item-title,
  .dx-accordion-item.dx-state-active > .dx-accordion-item-title {
    background: none;
  }

  .dx-accordion-item-title .dx-accordion-item-title-caption {
    left: 0;
  }

  .dx-accordion-item-title::before {
    right: 0;
  }
}

app-base-accordion-panel {
  .toolbar {
    display: flex;

    .flex-right {
      margin-left: auto;
    }
  }
}
