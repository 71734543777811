.card {
  margin: 1rem 0 !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border: 0 !important;
  overflow: hidden;
  background-color: $napp-color-white !important;
  box-shadow: $napp-box-shadow;
  font-family: $napp-body-font-family !important;

  .badge {
    margin-bottom: 10px;
    margin-right: 5px;
  }
}

.mobile-back {
  margin: 0px -1.25rem;
}

.no-style-card .card {
  border: 0 !important;
  box-shadow: none;
  border-radius: 0 !important;
}

.napp-card-header {
  min-height: 100px; // needed for IE
  position: relative;
}

.napp-card-body {
  .card-body {

    &:not(.author-image-container):not(.video-native-image)>img,
    *:not(.author-image-container):not(.video-native-image)>img {
      max-width: 100%;
      height: auto !important;
    }

    position: relative;
    border-top: 4px solid $napp-color-primary;

    h4.event-title {
      margin-top: 2.5rem;
    }

    .card-title {
      margin-top: 1rem;
    }
  }
}

.napp-card-footer {
  display: block !important;
  padding: 0 0 15px 0 !important;
  font-size: $napp-small-font-size;

  .dx-button {
    width: 100%;
  }

  .card-footer {
    display: block !important;
    border-top: none !important;
    padding: 0.25rem 1.25rem !important;
    background: none !important;
  }
}

.like-list {
  font-size: larger;
  padding: 5px;
  display: flex;
  align-items: center;
}

.like-list-author-image img {
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.dx-popup-content {
  overflow: auto;
}