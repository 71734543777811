@mixin footer {
  footer {
    margin-bottom: 0;
  }

  [data-category="presentaus-da"] .sponsor {
    width: calc(100% / 3 - 1rem);
  }

  .sponsor {
    width: calc(100% / 3 - 1rem);
  }

  .sponsor:last-child {
    margin-right: auto;
  }

  .sponsors::after {
    content: "";
    margin-right: auto;
  }
}

