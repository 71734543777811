.dx-tabpanel-tabs {
  margin-bottom: 10px;
}

.dx-tabpanel .dx-tabs {
  background: none;
}

.dx-tabpanel .dx-tab {
  padding: 12px 10px;
}
