footer {
  //margin-bottom: calc(-1 * var(--sab)) !important;
  padding-bottom: var(--sab) !important;
  background: $napp-color-bottom-navigation-bg;

  &.sponsors .adressbox {
    padding: 8px;
  }

  .adressbox {
    padding: 10px;

    &.logos-sponsors {
      padding-bottom: 0px;

      span.logo {
        min-height: 2rem;
        margin-bottom: 10px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      span.sponsors-caption {
        font-size: 0.9rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: 0.5rem;
      }
    }

    &.icon-only {
      font-size: 2rem;
    }
  }

  .adressbox > span {
    display: inline-block;
    color: legible-text-color($napp-color-bottom-navigation-bg);
  }

  .adressbox a {
    color: legible-text-color($napp-color-bottom-navigation-bg);
  }

  .adressbox span {
    margin-left: 15px;
    margin-right: 15px;

    .fa {
      margin-right: 5px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

}

.sponsors-container {
  padding: 1rem 0;
}

.sponsors-category {
  max-width: 1200px;
  margin: auto;
  padding: 0 3rem;
}

.sponsors-category-title {
  text-transform: uppercase;
}

.sponsors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: auto;
}

.sponsor {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-shadow: 0 0.6rem 1.15rem hsla(0, 0%, 0%, 0.25);
  box-shadow: 0 0.6rem 1.15rem hsla(0, 0%, 0%, 0.25);
  position: relative;
  z-index: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 12rem;
  margin: 0 1rem 1.25rem 0rem;
  padding: 3rem;
  -webkit-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
  background-color: hsla(0, 0%, 100%, 0.8);
  width: calc(100% / 2 - 1rem);
}

.sponsor:hover,
.sponsor:focus {
  -webkit-box-shadow: 0 0.4rem 1.3rem hsla(0, 0%, 0%, 0.35);
  box-shadow: 0 0.4rem 1.3rem hsla(0, 0%, 0%, 0.35);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.sponsor:active {
  -webkit-box-shadow: 0 0.5rem 1.15rem hsla(0, 0%, 0%, 0.4);
  box-shadow: 0 0.5rem 1.15rem hsla(0, 0%, 0%, 0.4);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}



@include media-breakpoint-down(xs) {
  .sponsor {
    width: 100%;
  }
}

.sponsors img {
  width: auto;
  max-width: 99%;
  height: auto;
  max-height: 100%;
}

[data-category="presentaus-da"] .sponsor {
  width: 100%;
  height: 7rem;
  padding: 1rem 2rem;
}
