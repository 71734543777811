.mgmt-list {
  .dx-list-item-content {
    padding: 0;
    display: flex;

    .item-content {
      padding: 14px 16px 13px;
      display: inline-flex;
      flex-grow: 1;
    }
  }

  .dx-dropdownbutton {
    display: none;
    .dx-dropdownbutton-action {
      height: auto;
    }
  }
  .dx-item {
    &.dx-state-hover, &.dx-state-focused, &.dx-state-active {
      .dx-dropdownbutton {
        display: inline-block;
      }
    }
  }
}

.dx-list-search.dx-searchbox .dx-clear-button-area .dx-icon-clear {
  font-size: 14px;
}

.backendSettingsGrid .value-container {
  width: 100%;
  height: 100%;
  max-height: 250px;
  word-break: keep-all;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  overflow: auto;
}
