:root {
  --sat: 0px;
  --sar: 0px;
  --sab: 0px;
  --sal: 0px;
}
@supports (padding-top: constant(safe-area-inset-top)) {
  :root {
    --sat: constant(safe-area-inset-top);
    --sar: constant(safe-area-inset-right);
    --sab: constant(safe-area-inset-bottom);
    --sal: constant(safe-area-inset-left);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  :root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }
}

html,
body {
  height: 100%;
  height: 100vh;
}
body {
  font-family: $napp-body-font-family;
  font-size: $napp-body-font-size;
  padding-top: var(--sat);
  padding-right: var(--sar);
  padding-bottom: var(--sab);
  padding-left: var(--sal);
  min-height: 100%;
  min-height: 100vh;
}

body.sidebar-open {
  overflow: hidden;
}

::selection {
  color: legible-text-color($napp-color-primary);
  background-color: $napp-color-primary;
}

h3,
.h3 {
  font-size: $napp-subheadline-size;
}

h4,
.h4 {
  font-size: $napp-body-font-size;
  font-weight: bold;
}

h5,
.h5 {
  font-size: $napp-body-font-size;
  color: napp-theme-color(primary, base);
}

a {
  text-decoration: none;
  color: napp-theme-color("primary", "base");
  outline: none;

  &:hover {
    color: napp-theme-color("primary", "base");
    text-decoration: none;
  }
}

.dx-widget {
  font-size: $napp-body-font-size;
}

.indent-dx-switch .dx-field-item-content {
  padding-left: 16px;
}

.small {
  font-size: $napp-small-font-size;
}

.noData {
  font-size: $napp-small-font-size;
  font-style: italic;
  color: napp-theme-color(neutral, dark);
}

.hint {
  font-size: $napp-small-font-size;
  font-style: italic;
  color: napp-theme-color(neutral, dark);

  strong {
    color: black;
    font-style: normal;
  }
}

.page-wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
}

.separator {
  color: napp-theme-color(neutral, dark);
}

.dx-loadindicator-segment-inner {
  border-color: $napp-color-primary;
  border-bottom-color: transparent;
}

#content {
  width: 100%;
  transition: all 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100vh;
}

#content.closed {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#content.left,
#content.right {
  overflow-y: visible;
  pointer-events: none;
}

.header,
.footer {
  flex: none;
}

.header {
  height: $napp-header-height;
  height: calc(#{$napp-header-height} + var(--sat));
  padding-top: var(--sat);
}

.intro-image {
  padding: 0;
  margin-bottom: 20px;
  img {
    max-width: 100%;
    height: auto !important;
  }
}

span.my-event {
  position: absolute;
  right: 5px;
  top: 10px;
  color: white;
  background-color: $napp-color-primary;
}

.team-logos {
  position: absolute;
  display: block;
  width: 100%;
  top: -50px;
  text-align: center;
  margin-left: -20px;

  img {
    height: 100px;
    width: 100px;
    border: 5px solid $napp-color-ultra-light-gray !important;
    background-color: $napp-color-ultra-light-gray !important;
    border-radius: 50%;
  }
}

.event-single {
  .team-logos {
    img {
      border: 5px solid white !important;
      background-color: white !important;
    }
  }
}

.dx-toast-message {
  display: flex;
  justify-content: center;
  padding: 10px;
  text-align: center;
  width: 100%;
}

main {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 20px;
}

.event-button-container {
  padding: 12px 0;
}

/* .footer-seperator {
  padding-bottom: $napp-footer-height + 20;
} */

.empty-page {
  margin-top: 50px;
  text-align: center;
}

.version {
  position: absolute;
  bottom: 10px;
  bottom: calc(10px + var(--sab));
  right: 10px;
  display: inline;
  color: $napp-color-gray;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

#main-page-wrapper {
  margin-top: $napp-header-height;

  .page-title {
    margin-left: 30px;
  }

  h1.page-title,
  h2.page-title {
    text-transform: uppercase;
    margin-top: 20px;
  }

  &.container {
    padding-right: 0;
    padding-left: 0;
  }

  .page-content {
    padding-bottom: 60px;

    p {
      margin-bottom: 0;
    }
  }

  .page-header {
    padding-top: 20px;
    h2 {
      margin-top: 15px;
      margin-bottom: 40px;
    }

    h3.subtitle {
      margin-top: -35px;
      margin-bottom: 30px;
      font-size: 1rem;
    }
  }

  .page-nav + .container-fluid {
    .page-header {
      h2 {
        margin-top: 0px;
      }
    }
  }

  .page-title {
    margin-left: 30px;
  }

  .page-container {
    background-color: white;
    box-shadow: $napp-box-shadow;
  }

  .child-layer {
    background-color: napp-theme-color("neutral", "base");
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: -60px;

    &:not(.no-navbar-gap) {
      padding-top: 0;
    }

    &.mobile-full-width {
      .container {
        padding: 0;
      }
    }
  }

  app-page {
    margin-top: $napp-header-height;
    display: block;

    &.no-navbar-gap {
      margin-top: -20px;
    }

    .page-actions-container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;

      .page-actions {
        padding-bottom: ($bottombar-height + $page-actions-bottom-margin);
        background-color: white;
      }
    }
  }
}

.author-image-container {
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid white;
  background-color: white;
  border-radius: 50%;
  padding-top: 5px;
  text-align: center;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &.default-img {
      content: url("/assets/themes/#{map-get($napp-user-image, 'url')}");
    }
  }

  i {
    font-size: 30px;
    text-align: center;
    color: napp-theme-color("neutral", "dark");
  }
}

.post-author-image-container .author-image-container {
  z-index: 7;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // media-query only for IE
  main {
    min-height: 100%;
  }
}

#main-container {
  margin-top: $napp-header-height;

  .page-title {
    margin-left: 30px;
  }

  h1.page-title,
  h2.page-title {
    text-transform: uppercase;
    margin-top: 20px;
  }
}

.dx-overlay-wrapper {
  z-index: 2010 !important;
  bottom: 0px;
  right: 0px;
  height: 90vh;
  width: 100vw;
}

.container-pages {
  img {
    max-width: 100%;
    height: auto !important;
  }
}

.ngx-gallery-icon-content {
  font-size: 25px;
}

.ngx-gallery-arrow-left .ngx-gallery-icon-content {
  &:before {
    content: "\f104";
  }
}

.ngx-gallery-arrow-right .ngx-gallery-icon-content {
  &:before {
    content: "\f105";
  }
}

.ngx-gallery-close.ngx-gallery-icon-content {
  &:before {
    content: "\f00d";
  }
}

.dx-gallery-indicator-item-active,
.dx-gallery-indicator-item-selected {
  background: $napp-color-primary;
}

.dx-gallery .dx-gallery-nav-button-prev.dx-state-active:after,
.dx-gallery .dx-gallery-nav-button-next.dx-state-active:after {
  background: $napp-color-primary;
}

body.container-h100 {
  #main-container {
    height: 100%;

    .dx-gallery {
      margin-top: -$napp-header-height;
    }
  }
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  height: 100%;
  display: flex;
  align-items: center;
}

.dx-gallery-indicator {
  padding-top: 10px;
}

.page-content {
  img {
    max-width: 100%;
  }
}

#block-system-main img,
.views-field img {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  padding: 3px;
}

/*
 Result
 */

.resulttable {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  width: 100%;
  font-family: Roboto, sans-serif;
  margin-top: 20px;
}

.resulttable tr {
  background-color: #c00;
  color: white;
  border: 1px solid #ddd;
  padding: 0.35em;
}

.resulttable td {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
  color: black;
}

.resulttable th,
.resulttable td {
  padding: 0.625em;
  text-align: center;
}

.dx-toast-content {
  z-index: 10502 !important;
  position: fixed;
  left: -16px;
  transform: none !important;
  max-width: none !important;
  padding-top: var(--sat);
}

.dx-overlay-wrapper.dx-toast-wrapper {
  z-index: 10501 !important;
  position: fixed !important;
  transform: none !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-top: 20px;
}

.video-container-native {
  position: relative;
  height: 56.25vmin;
  overflow: hidden;
  margin-top: 50px;

  .file-selection-fluid {
    height: 100% !important;
    position: relative;

    .thumb-container {
      width: 100%;
      height: 100%;
      justify-content: center;
      display: flex;

      advanced-image {
        height: 100%;
        display: flex;
        margin: auto;

        img {
          max-height: 100%;
          width: auto;
          margin: auto;
          display: block;
        }
      }
    }
  }
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container advanced-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

advanced-video video {
  width: 100%;
  height: 100%;
}

.grecaptcha-badge {
  display: none;
}

.footer {
  #sponsors-container {
    padding-bottom: 80px;
  }
}

.va-middle {
  vertical-align: middle;
}

#bottom-navigations {
  $bn-line-height: 30px;
  $bn-padding: 20px;
  $bn-padding-landscape: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  line-height: $bn-line-height;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $napp-color-bottom-navigation-bg;
  box-shadow: 0px -1px 5px 0px $napp-color-bottom-navigation-bg;

  div.bottom-navigations-background {
    background: $napp-color-bottom-navigation-inactive;

    div.link {
      padding-left: 0px;
      padding-right: 0px;

      a {
        display: flex;
        padding: $bn-padding;
        --bn-a-pb: max(#{$bn-padding}, var(--sab));
        padding-bottom: var(--bn-a-pb, #{$bn-padding});
        max-height: $bn-line-height + (2 * $bn-padding);
        max-height: calc(#{$bn-line-height} + #{$bn-padding} + var(--bn-a-pb, #{$bn-padding}));
        @media (max-height: 500px) and (orientation: landscape) {
          & {
            padding-top: $bn-padding-landscape;
            padding-bottom: $bn-padding-landscape;
            --bn-a-pb: max(#{$bn-padding-landscape}, calc(1.41421356 * var(--sab)));
            padding-bottom: var(--bn-a-pb, $bn-padding-landscape);
            max-height: $bn-line-height + (2 * $bn-padding-landscape);
            max-height: calc(#{$bn-line-height} + #{$bn-padding-landscape} + var(--bn-a-pb, $bn-padding-landscape));
          }
        }

        width: 100%;
        height: 100%;
        color: legible-inactive-text-color($napp-color-bottom-navigation-inactive);
        align-items: center;
        justify-content: center;

        .nav-icon {
          font-size: 24px;
          padding-right: 10px;
        }

        .nav-text {
          font-size: 16px;
          max-height: $bn-line-height;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          white-space: nowrap;
        }

        .napp-logo-brand:before {
          background-color: legible-inactive-text-color($napp-color-bottom-navigation-inactive);
        }

        &.active {
          color: legible-text-color($napp-color-bottom-navigation-active);
          background: $napp-color-bottom-navigation-active;

          .napp-logo-brand:before {
            background-color: legible-text-color($napp-color-bottom-navigation-active);
          }
        }
      }
    }
  }
}

a.um {
  border-radius: 0.5em;
  border: 1px solid $napp-color-lighten-20;
  background-color: $napp-color-lighten-20;
  color: legible-text-color($napp-color-lighten-20) !important;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: 400;
  white-space: nowrap;

  &::before {
    content: '@';
    display: inline-block;
    padding-right: 0.25em;
    font-weight: 600;
  }
}
