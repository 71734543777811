.top-bar {
  margin-top: calc(-1 * var(--sat)) !important;
  padding-top: var(--sat) !important;
  height: $napp-header-height;
  height: calc(#{$napp-header-height} + var(--sat));
  background-color: $napp-color-primary;
  text-align: center;
  position: relative;
  z-index: 1;
  box-shadow: inset 0 -10px 3px -10px rgba(0,0,0,0.5);

  .logo-bg {
    background-color: $napp-color-sidebar-logo-background;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    margin-top: 30px;
    width: 60px;
    height: 60px;

    i, img {
      transition: all .4s ease-in-out;
      transition-delay: 0.1s;
      transform: scale(0.1);
      color: $napp-color-primary;
    }

    .animate {
      i, img {
        transform: scale(1);
        font-size: 25px !important;
      }
    }

    svg {
      transition: all .4s ease-in-out;
      transition-delay: 0.1s;
      transform: scale(0.1);
    }

    svg.animate {
      transform: scale(1);
    }
  }
}

#sidebar-left {

  .top-bar .logo-bg {
    padding: 15px;

    @supports(margin: unquote('max(0px)')) {
      margin-left: unquote('max(0px, var(--sal))');
    }
  }

  width: $napp-sidebar-mobile-width;
  position: fixed;
  top: 0;
  padding-top: var(--sat);
  padding-bottom: var(--sab);
  left: 0;
  height: 100%;
  z-index: 2002;
  background: $napp-color-white;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);

  &.closed {
    margin-left: $napp-sidebar-mobile-width * -1;
  }

  #close-icon {
    position: absolute;
    top: 15px;
    top: calc(15px + var(--sat));
    right: 15px;
    z-index: 200;
    border-radius: 50% !important;
    transition-duration: 0.5s;
    transition-delay: 0.1s;
    transition-timing-function: ease;
    background: none;
    color: white;

    i.dx-icon {
      color: white;
    }

    &:hover {
      transform: rotate(-90deg);
    }
  }

  .top-bar {
    .logo-bg {

      svg,
      .napp-logo.napp-logo-brand {
        transition: all .4s ease-in-out;
        transition-delay: 0.1s;
        transform: scale(0.1);
        path {
          fill: $napp-color-primary;
        }
      }

      svg.animate,
      .napp-logo.napp-logo-brand.animate:before {
        transform: scale(1);
        font-size: 25px;
      }
    }
  }
}

#sidebar-right {
  width: $napp-sidebar-mobile-width;
  position: fixed;
  top: 0;
  padding-top: var(--sat);
  padding-bottom: var(--sab);
  right: 0;
  height: 100%;
  z-index: 2001;
  color: $napp-body-text-color;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: block;

  &.closed {
    margin-right: $napp-sidebar-mobile-width * -1;
  }

  #close-icon {
    position: absolute;
    top: 15px;
    top: calc(15px + var(--sat));
    left: 15px;
    z-index: 200;
    transition-duration: 0.5s;
    transition-delay: 0.1s;
    transition-timing-function: ease;
    background: none;

    &:hover {
      transform: rotate(90deg);
    }

    i.dx-icon {
      color: white;
    }
  }

  .top-bar {
    background-color: $napp-color-primary;

    .logo-bg {
      @supports(margin: unquote('max(0px)')) {
        margin-right: unquote('max(0px, var(--sar))');
      }
    }
  }

  .language-selector-container {
    position: absolute;
    top: 15px;
    top: calc(15px + var(--sat));
    right: 15px;
    right: calc(15px + var(--sar));
    width: 20%;

    .dx-texteditor-input {
      color: $napp-color-white;
    }

    .dx-texteditor.dx-editor-underlined:after,
    .dx-texteditor.dx-editor-filled:after {
      border-bottom-color: $napp-color-white;
    }

    .dx-dropdowneditor-icon {
      color: $napp-color-white;
    }
  }

  .profile-container {
    text-align: center;
    color: napp-theme-color('primary', 'base');
    .profile {
      color: napp-theme-color('primary', 'base');
      display: inline-block;
      font-size: $napp-small-font-size;
    }
  }

  .badge {
    border-radius: 50%;
    background: $napp-color-primary;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: legible-text-color($napp-color-primary);
    padding: 0;
  }
}

.sidebar-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  &.active {
    display: block;
    opacity: 1;
  }
}

.position-fixed-fix {
  #sidebar-right,
  #sidebar-left,
  .sidebar-overlay {
    position: absolute;
  }
}
