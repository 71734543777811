app-scroll-view-with-bottom-bar {

  .dx-scrollview .dx-scrollable-content {
    margin-bottom: 40px;
  }

  .scroll-view-footer-bar {
    width: 100%;
    background: white;
    text-align: right;
    padding-top: 20px;
    padding-bottom: var(--sab);
  }

  dx-scroll-view.scroll-view-footer-bar-after {
    height: 80%;
    height: calc(100% - 56px);
    height: calc(100% - 56px - var(--sab));
  }

}
